import React from 'react'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuList from '@material-ui/core/MenuList'
import Popover from '@material-ui/core/Popover'
import LaunchIcon from '@material-ui/icons/Launch'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { routes, replaceAppId } from '../../helper/routes'
import { useHistory } from 'react-router-dom'


// const useStyles = makeStyles(theme => ({
//   content: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     width: 250
//   },
//   chip: {
//     margin: 2,
//   }
// }))


export default function AppPopover(props) {
  // const classes = useStyles()
  const history = useHistory()
  const { app, anchorEl, handleClose, handleAppOpen, handleAppDelete, canEditApp, canDeleteApp } = props

  const open = Boolean(anchorEl)
  const id = open ? 'app-popover' : undefined

  const handleAppEdit = () => {
    history.push(replaceAppId(routes.appEdit, app.route_id))
  }

  if (!app)
    return null

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {/* <Box className={classes.content}>
        {app.title}
      </Box> */}
      {/* <Divider variant="middle" /> */}
      <MenuList>

        <MenuItem dense={true} onClick={handleAppOpen}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <LaunchIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Apri</Typography>
        </MenuItem>
        {canEditApp &&
          <MenuItem dense={true} onClick={handleAppEdit}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Modifica</Typography>
          </MenuItem>
        }
        {canDeleteApp &&
          <MenuItem dense={true} onClick={handleAppDelete}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Elimina</Typography>
          </MenuItem>
        }
      </MenuList>
    </Popover>

  )
}
