import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import _ from 'lodash'
import appPhImage from '../resource/app-placeholder.png'
import { CardHeader, IconButton, CardActionArea } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import AppPopover from './layout/AppPopover'
import AlertDialog from './dialog/AlertDialog'
import { useHistory } from 'react-router-dom'
import { routes, replaceAppId } from '../helper/routes'



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 300,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    fontSize: '1rem'
  }
}));



export default function AppCard(props) {
  const classes = useStyles()
  const history = useHistory()
  const { app, permissionMap } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

  const handleAppOpen = () => {
    history.push(replaceAppId(routes.appHome, app.route_id))
  }

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const handleDeletDialogOpen = () => {
    setDeleteDialogOpen(true)
  }

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false)
  }

  const handleDeleteConfirm = () => {
    // todo delete
    handleDeleteDialogClose()
    handlePopoverClose()
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardActionArea
          onClick={handleAppOpen}>
          <CardMedia
            className={classes.media}
            image={!_.isNil(app.img_url) && app.img_url !== '' ?
              app.img_url : appPhImage
            }
            // title={'app-' + app.id}
          />
        </CardActionArea>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handlePopoverOpen}>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          }
          title={app.title}
          classes={{
            title: classes.title
          }}
        />
      </Card>
      <AppPopover app={app} anchorEl={anchorEl}
        handleClose={handlePopoverClose}
        handleAppOpen={handleAppOpen}
        handleAppDelete={handleDeletDialogOpen}
        canEditApp={permissionMap['update-all'] || permissionMap['update-application']}
        canDeleteApp={permissionMap['delete-all'] || permissionMap['delete-application']}
      />
      <AlertDialog
        title="Conferma eliminazione"
        content="Eliminare l'applicazione?"
        open={deleteDialogOpen}
        handleClose={handleDeleteDialogClose}
        handleConfirm={handleDeleteConfirm}
      />
    </div>

  )
}
